import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInfrontStockItemSimple, InfrontAllStocksSimpleState, IUpdateStockItem, UpdateStockField } from "./types";

const initialState = {
  allStocks: [],
  status: "idle",
  error: null,
} as InfrontAllStocksSimpleState;

const emptyStock = {
  index: -1,
  feed: -1,
  ticker: "",
  isin: "",
  name: "",
  displaySymbolStatus: "",
} as IInfrontStockItemSimple;

const updateStock = (state: InfrontAllStocksSimpleState, action: PayloadAction<IUpdateStockItem>, field: UpdateStockField) => {
  const stock = state.allStocks.find(o => o.index === action.payload.index && o.feed === action.payload.feed);
  if (stock) {
    stock[field] = action.payload.value as string;
  } else {
    state.allStocks.push({
      ...emptyStock,
      index: action.payload.index,
      feed: action.payload.feed,
      [field]: action.payload.value,
    });
  }
};

export const infrontAllStocksSimple = createSlice({
  name: "infront",
  initialState,
  reducers: {
    setTicker: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "ticker");
    },
    setIsin: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "isin");
    },
    setName: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "name");
    },
    setDisplaySymbolStatus: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "displaySymbolStatus");
    },
  },
});
export const { setTicker, setIsin, setName, setDisplaySymbolStatus } = infrontAllStocksSimple.actions;
export default infrontAllStocksSimple.reducer;
