export const AdvisorPrefix = "ADVISOR_";
export const AdvisorFullAccessRoleString = `${AdvisorPrefix}FULLACCESS`;
export const AdvisorSupportRoleString = `${AdvisorPrefix}SUPPORT`;

export const isAdvisor = (role: unknown): boolean => {
  if (typeof role !== "string") {
    return false;
  }
  return role.startsWith(AdvisorPrefix);
};
