import useSWR, { SWRResponse } from "swr";
import { IUsePowerOfAttorneyLoggedInUser } from "./types";
import useAdvisor from "../useAdvisor";
import commonSwrConfig from "../commonSwrConfig";
import { IPoaInfo } from "@portal/types/api/IPoaInfo";

const usePowerOfAttorneyLoggedInUser: IUsePowerOfAttorneyLoggedInUser = () => {
  const { isAdvisor, isAdvisorAndNoImpersonation } = useAdvisor();

  const fetcher = async (url: URL) => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };

  const { data, error, isLoading }: SWRResponse<IPoaInfo[], unknown> = useSWR(
    isAdvisor ? (isAdvisorAndNoImpersonation ? null : "/api/powerOfAttorney/poa") : "/api/powerOfAttorney/poaLoggedInUser",
    fetcher,
    commonSwrConfig(),
  );

  return {
    data,
    isLoading,
    error,
  };
};

export default usePowerOfAttorneyLoggedInUser;
