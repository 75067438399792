import { RootState } from "@portal/redux/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISendMessageState } from "./types";

const initialState: ISendMessageState = {
  status: "idle",
};

export const sendMessage = createAsyncThunk("sendMessage", async (_, thunkAPI): Promise<void> => {
  const state = thunkAPI.getState() as RootState;

  const formData = new FormData();

  const newMessage = state.other.ui.newMessage;
  formData.append("subject", newMessage.formData.subject);
  formData.append("messageBody", newMessage.formData.text);
  for (const i in newMessage.formData.attachments) {
    const blob = await fetch(newMessage.formData.attachments[i].url).then(r => r.blob());
    formData.append("attachments", blob, newMessage.formData.attachments[i].name);
  }

  const response = await fetch("/api/mailbox/fromMessages", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) throw response;
});

export const sendMessageReducer = createSlice({
  name: "sendMessage",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(sendMessage.pending, state => {
        state.status = "loading";
      })
      .addCase(sendMessage.fulfilled, state => {
        state.status = "succeeded";
      })
      .addCase(sendMessage.rejected, state => {
        state.status = "failed";
      });
  },
});
export const getStatus = (state: RootState) => state.other.api.sendMessage.status;
export const { reset } = sendMessageReducer.actions;

export default sendMessageReducer.reducer;
