import Icon from "@portal/components/atoms/icons";
import Typography from "@portal/components/atoms/typography";
import { MenuItem } from "@headlessui/react";
import { FC } from "react";
import { IUserMenuItemProps } from "./types";

const UserMenuItem: FC<IUserMenuItemProps> = ({
  onClick,
  className,
  iconName,
  children,
  subItem,
  leftSelectedIcon,
  disabled,
}) => (
  <MenuItem>
    {({ focus }) => (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`${className ? className : "border-t"} ${
          focus ? "bg-blueHover" : subItem ? "bg-greyLight" : ""
        } flex justify-between items-center w-full py-2 px-3.5 space-x-4`}
      >
        <div className={subItem ? `${disabled ? "bg-greyShadow" : "bg-blueLight"} text-white rounded-full p-1` : ""}>
          <Icon name={iconName} size="xsmall" />
        </div>
        {children instanceof Object ? (
          children
        ) : (
          <div className="text-defaultText flex truncate grow">
            <Typography variant="Body">{children}</Typography>
          </div>
        )}
        {leftSelectedIcon && (
          <div className="justify-self-end text-green">
            <Icon name="checkCircleIcon" size="xsmall" />
          </div>
        )}
      </button>
    )}
  </MenuItem>
);

export default UserMenuItem;
