import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISuitabilityReport } from "../../types/robot/ISuitabilityReport";
import { createSuitabilityReport } from "./api/createSuitabilityReport/reducer";

const initialState = {
  text: [] as string[] | undefined,
};

export const suitabilityReportReducer = createSlice({
  name: "suitabilityReport",
  initialState,
  reducers: {
    setSuitabilityReport: (state, action: PayloadAction<ISuitabilityReport>) => {
      state.text = action.payload.text;
    },
    clearSuitabilityReport: state => {
      state.text = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(createSuitabilityReport.fulfilled, (state, action) => {
      state.text = action.payload?.text;
    });
  },
});

export const { setSuitabilityReport, clearSuitabilityReport } = suitabilityReportReducer.actions;
export default suitabilityReportReducer.reducer;
