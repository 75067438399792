import useSWR, { SWRResponse } from "swr";
import { IUseElectronicIdentification } from "./types";
import commonSwrConfig from "../commonSwrConfig";

const useElectronicIdentification: IUseElectronicIdentification = () => {
  const fetcher = async (url: URL): Promise<boolean> => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };

  const { data, isLoading, error }: SWRResponse<boolean, unknown> = useSWR(`/api/electronicIdentification`, fetcher, {
    ...commonSwrConfig(),
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
  });

  return {
    data,
    error,
    isLoading,
  };
};

export default useElectronicIdentification;
