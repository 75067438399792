import React from "react";
import { PortalFooter } from "../footer";
import { ILayout } from "./types";
import { useSession } from "next-auth/react";
import { NORNE_CENTERID } from "@portal/assets/banks";
import { useRouter } from "next/router";
import Header from "./header";

const LayoutNotVerifiedAml = ({ children }: React.PropsWithChildren<ILayout>) => {
  const router = useRouter();
  const { data } = useSession();
  let centerId = data?.centerId ?? (router.query["centerId"] as string) ?? (router.query["centerid"] as string);
  if (centerId === undefined) centerId = NORNE_CENTERID;

  const isNorne = centerId === NORNE_CENTERID;

  return (
    <div className={`flex flex-col w-full text-defaultText bg-greyBackground min-h-[100vh]`}>
      <div className="print:hidden bg-headerBackground text-headerMenuText">
        <div className="flex border-b">
          <Header centerId={centerId} />
        </div>
      </div>
      <div className="container mx-auto md:my-4">{children}</div>
      {isNorne && <PortalFooter centerId={centerId} />}
    </div>
  );
};

export default LayoutNotVerifiedAml;
