import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const trackMessage = (message: string) => {
  try {
    appInsights.trackTrace({ message });
  } catch (e) {
    // no-op
  }
};

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.NEXT_PUBLIC_CLIENT_APPINSIGHTS_CONN_STR,
    /* Other Configuration Options:
     *** see https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript?tabs=snippet#configuration ***
     */
  },
});

export default appInsights;
