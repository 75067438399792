import { createAsyncThunk } from "@reduxjs/toolkit";
import { PoaUser } from "iron-session";
import { mutate } from "swr";
import { runInitialRequests } from "../../app";
import { clearOrders } from "../orders/reducer";
import { ISwitchUserRequest } from "./types";

export const switchPoaUser = createAsyncThunk("switchUser", async (req: ISwitchUserRequest, thunkAPI): Promise<PoaUser> => {
  const url = "/api/poaUser/";

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    console.error(response.statusText);
    throw new Error(response.statusText);
  }
  await mutate(() => true, undefined, { revalidate: true });
  thunkAPI.dispatch(clearOrders());
  thunkAPI.dispatch(runInitialRequests(req.centerId));
  window.localStorage.removeItem("switch-user");
  window.localStorage.setItem("switch-user", "true");

  return response.json();
});
