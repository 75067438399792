import "../styles/globals.css";
import "../styles/banks.css";
import "../styles/infront.css";
import "../styles/external/infront/styles/css/infrontFramework-latest.css";
import "../styles/external/infront/styles/themes/light-latest/theme.css";
import PageSpinner from "@portal/components/organisms/pageSpinner";
import Head from "next/head";
import useFeedHubStreams from "@portal/hooks/useFeedHubStreams";
import useVerifySession from "@portal/hooks/useVerifySession";
import useInitApp from "@portal/hooks/useInitApp";
import appInsights from "@portal/appInsights";
import useVerifyAmlStatus from "@portal/hooks/useVerifyAmlStatus";
import GeneralAmlErrorPage from "@portal/components/pages/generalAmlErrorPage";
import { NORNE_CENTERID } from "@portal/assets/banks";
import { useEffect, useRef } from "react";
import store, { persistor } from "../redux/store";
import type { AppProps } from "next/app";
import { SessionProvider, useSession } from "next-auth/react";
import { Provider } from "react-redux";
import { TextResources } from "../assets/text/TextResources";
import { useRouter } from "next/router";
import { PersistGate } from "redux-persist/integration/react";
import { SWRConfig } from "swr";
import { Mutex } from "async-mutex";
import { AdminPages } from "@portal/helpers/adminPages";
import useReInitializedTab from "@portal/hooks/useReInitializedTab";
import useLoadInfrontSDK from "@portal/hooks/useLoadInfrontSDK";
import useAdvisor from "@portal/hooks/useAdvisor";
import { ErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "@portal/components/pages/generalErrorPage";
import useCenterId from "@portal/hooks/useCenterId";

if (typeof window !== "undefined") {
  try {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  } catch (error) {
    console.error("failed loading app insights", error);
  }
}

const Portal = ({ Component, pageProps }: AppProps) => {
  const { data: session, status, update } = useSession();
  const centerId = useCenterId();
  const { isAdvisor } = useAdvisor();

  const mutex = useRef(new Mutex());
  useInitApp({ userId: session?.user?.id, centerId: centerId ?? "" });
  useFeedHubStreams(centerId ?? "");
  useVerifySession();
  useReInitializedTab();

  const { amlVerified, error: amlError } = useVerifyAmlStatus();
  const router = useRouter();

  useEffect(() => {
    const handleComplete = (url: string) => url === router.asPath && appInsights.trackPageView();
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  });
  useEffect(() => {
    const handleNoScrollAtNumberFields = () => {
      document.addEventListener("wheel", () => {
        if (
          document.activeElement instanceof HTMLElement &&
          document.activeElement.tagName === "INPUT" &&
          document.activeElement.getAttribute("type") === "number"
        ) {
          document.activeElement.blur();
        }
      });
    };
    handleNoScrollAtNumberFields();
  });

  useLoadInfrontSDK();

  return (
    <SWRConfig
      value={{
        onError: async (err: unknown) => {
          //handle expired access token (update session for trigger refresh token)
          if (status === "authenticated" && err === 401) {
            if (mutex.current.isLocked()) return;
            mutex.current.runExclusive(async () => {
              await update();
            });
          }
        },
      }}
    >
      {amlError ? (
        <GeneralAmlErrorPage />
      ) : (
        <>
          {amlVerified || isAdvisor ? (
            <ErrorBoundary fallback={fallback} onError={logBoundaryError}>
              <Component {...pageProps} />
            </ErrorBoundary>
          ) : (
            <PageSpinner />
          )}
        </>
      )}
    </SWRConfig>
  );
};

const AdminPortal = ({ Component, pageProps }: AppProps) => {
  const { status, update } = useSession();
  const mutex = useRef(new Mutex());
  useVerifySession();
  useLoadInfrontSDK();
  return (
    <SWRConfig
      value={{
        onError: async (err: unknown) => {
          //handle expired access token (update session for trigger refresh token)
          if (status === "authenticated" && err === 401) {
            if (mutex.current.isLocked()) return;
            mutex.current.runExclusive(async () => {
              await update();
            });
          }
        },
      }}
    >
      <ErrorBoundary fallback={fallback} onError={logBoundaryError}>
        <Component {...pageProps} />
      </ErrorBoundary>
    </SWRConfig>
  );
};

const OpenPagesComp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { data } = useSession();

  // center id for the bank
  let centerId = data?.centerId ?? (router.query["centerId"] as string) ?? (router.query["centerid"] as string);
  if (centerId === undefined) centerId = NORNE_CENTERID;
  const theme = GetBankColorTheme(centerId);

  return (
    <div className={`flex w-full ${theme}`}>
      <ErrorBoundary fallback={fallback} onError={logBoundaryError}>
        <Component {...pageProps} />
      </ErrorBoundary>
    </div>
  );
};

export default function ReduxProvider(props: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const centerId = props.pageProps.session?.centerId ?? NORNE_CENTERID;
    const theme = GetBankColorTheme(centerId);
    document.body.classList.add(theme);
  });

  const openPage = !props.pageProps.session;

  return (
    <SessionProvider session={props.pageProps.session} refetchOnWindowFocus={true} refetchInterval={8 * 60}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Head>
            <title>{TextResources.NORNE_LABEL}</title>
            <meta name="description" content="Oversikt og handel av fond" />
            <link rel="icon" href="/favicon.ico" />
          </Head>
          {openPage ? (
            <OpenPagesComp {...props} />
          ) : AdminPages.includes(router.pathname) ? (
            <div className={`flex w-full`}>
              <AdminPortal {...props} />
            </div>
          ) : (
            <div className={`flex w-full`}>
              <Portal {...props} />
            </div>
          )}
        </PersistGate>
      </Provider>
    </SessionProvider>
  );
}

const fallback = <GeneralErrorPage />;

const logBoundaryError = (error: Error, info: { componentStack: string }) => {
  console.error("ErrorBoundary", error, info);
};

export const GetBankColorTheme = (centerId: string) => {
  if (centerId === "0000") return "theme_norne";
  if (centerId === "3411") return "theme_fanaSparebank";
  if (centerId === "9090") return "theme_eika";
  if (centerId === "3910") return "theme_sparebankenMøre";
  if (centerId === "3890") return "theme_sparebankenSognOgFjordane";
  if (centerId === "9820") return "theme_obosBank";
  if (centerId === "3601") return "theme_sparebankenVest";
  if (centerId === "2220") return "theme_sparebankenØst";
  if (centerId === "4484") return "theme_aasenSparebank";
  if (centerId === "4589") return "theme_sekstiAatteGraderNord";
  if (centerId === "4730") return "theme_sekstiAatteGraderNord";
  if (centerId === "4605") return "theme_sekstiAatteGraderNord";

  return "theme_default";
};
