import { TextResources } from "@portal/assets/text/TextResources";
import { RootState } from "@portal/redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sendMessage } from "../../api/sendMessage/reducer";
import { IFile, IReplyMessage, IReplyMessageFormData } from "./types";

const initialState = {
  formData: {
    subject: "",
    text: "",
    attachments: [],
    thread: "",
  } as IReplyMessageFormData,
  subjectError: "",
  messageError: "",
  fileError: "",
  apiError: "",
} as IReplyMessage;

const validateAttachmentsTotalSize = (attachments: IFile[]) => {
  return attachments.reduce((size, attachment) => {
    return size + attachment.size;
  }, 0) > 104857600
    ? TextResources.MAILBOX_FILE_SIZE_ERROR
    : "";
};

export const uiReplyMessage = createAsyncThunk("uiReplyMessage", async (_, thunkAPI) => {
  thunkAPI.dispatch(validateForm());
  const state = thunkAPI.getState() as RootState;
  const message = state.other.ui.replyMessage;
  if (message.subjectError || message.messageError || message.fileError) {
    return;
  }
  thunkAPI.dispatch(sendMessage());
});

export const replyMessageReducer = createSlice({
  name: "replyMessage",
  initialState,
  reducers: {
    setSubject: (state, action: PayloadAction<string>) => {
      state.formData.subject = action.payload;
    },
    setText: (state, action: PayloadAction<string>) => {
      state.formData.text = action.payload;
    },
    setThread: (state, action: PayloadAction<string>) => {
      state.formData.thread = action.payload;
    },
    addFile: (state, action: PayloadAction<IFile>) => {
      state.formData.attachments.push(action.payload);
      state.fileError = validateAttachmentsTotalSize(state.formData.attachments);
    },
    deleteFile: (state, action: PayloadAction<string>) => {
      state.formData.attachments = state.formData.attachments.filter(file => file.id !== action.payload);
      state.fileError = validateAttachmentsTotalSize(state.formData.attachments);
    },
    setFileError: (state, action: PayloadAction<string>) => {
      state.fileError = action.payload;
    },
    setMessageError: (state, action: PayloadAction<string>) => {
      state.messageError = action.payload;
    },
    setApiError: (state, action: PayloadAction<string>) => {
      state.apiError = action.payload;
    },
    validateForm: state => {
      state.messageError = state.formData.text === "" ? TextResources.MAILBOX_MESSAGE_MISSING : "";
      state.fileError = validateAttachmentsTotalSize(state.formData.attachments);
    },
    validateText: state => {
      state.messageError = state.formData.text === "" ? TextResources.MAILBOX_MESSAGE_MISSING : "";
    },
    validateSubject: state => {
      state.subjectError = state.formData.subject === "" ? TextResources.MAILBOX_SUBJECT_MISSING : "";
    },
    clearForm: state => {
      state.formData = initialState.formData;
      state.subjectError = "";
      state.messageError = "";
      state.apiError = "";
      state.fileError = "";
    },
  },
});

export const {
  setSubject,
  setText,
  addFile,
  setFileError,
  setApiError,
  deleteFile,
  setMessageError,
  validateForm,
  validateText,
  validateSubject,
  setThread,
  clearForm,
} = replyMessageReducer.actions;
export default replyMessageReducer.reducer;
