import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@portal/redux/store";
import { IWithdrawalFormData, IWithdrawalState } from "./types";
import { IWithdrawalRequest } from "@portal/types/api/IWithdrawalRequest";
import { getAmountNumber } from "@portal/helpers/FormatAmount";
import { handleForbiddenError } from "@portal/helpers/handleForbiddenError";

const initialState = {
  formData: {
    portfolioId: "",
    amount: "",
    bankAccountNumber: "",
  } as IWithdrawalFormData,
  apiError: "",
  amountError: "",
  bankAccountError: "",
  portfolioError: "",
  loading: false,
  showCompleteMessage: false,
  step: 0,
} as IWithdrawalState;

export const submitWithdrawal = createAsyncThunk("postWithdrawal", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const formData = state.other.ui.withdrawal.formData;

  const request = {
    portfolioId: formData.portfolioId,
    amount: getAmountNumber(formData.amount),
    accountNumber: formData.bankAccountNumber,
  } as IWithdrawalRequest;
  const url = "/api/withdrawal/payout";
  const response = await fetch(url, {
    method: "POST",
    headers: [
      ["accepts", "application/json"],
      ["Content-Type", "application/json"],
    ],
    body: JSON.stringify(request),
  });
  if (!response.ok) {
    const handledForbiddenError = await handleForbiddenError(response);
    throw Error(handledForbiddenError ?? "En feil oppstod ved uttak. Vennligst prøv igjen seinere");
  }
});

export const withdrawalReducer = createSlice({
  name: "withdrawal",
  initialState,
  reducers: {
    setFormData: (state, action: PayloadAction<IWithdrawalFormData>) => {
      state.formData = action.payload;
    },
    setPortfolioId: (state, action: PayloadAction<string>) => {
      state.formData.portfolioId = action.payload;
    },
    setBankAccountNumber: (state, action: PayloadAction<string>) => {
      state.formData.bankAccountNumber = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.formData.amount = action.payload;
    },
    setApiError: (state, action: PayloadAction<string>) => {
      state.apiError = action.payload;
    },
    setAmountError: (state, action: PayloadAction<string>) => {
      state.amountError = action.payload;
    },
    setBankAccountError: (state, action: PayloadAction<string>) => {
      state.bankAccountError = action.payload;
    },
    setPortfolioError: (state, action: PayloadAction<string>) => {
      state.portfolioError = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    clearForm: state => {
      state.formData = initialState.formData;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(submitWithdrawal.pending, state => {
        state.apiError = "";
        state.loading = true;
      })
      .addCase(submitWithdrawal.fulfilled, (state, _) => {
        state.loading = false;
        state.formData = initialState.formData;
        state.apiError = "";
        state.step = 1;
      })
      .addCase(submitWithdrawal.rejected, (state, action) => {
        state.loading = false;
        state.showCompleteMessage = false;
        state.apiError = action.error.message ?? "En feil oppstod ved uttak. Vennligst prøv igjen seinere";
      });
  },
});

export const {
  setFormData,
  setAmount,
  setBankAccountNumber,
  setPortfolioId,
  setApiError,
  clearForm,
  setStep,
  setAmountError,
  setBankAccountError,
  setPortfolioError,
} = withdrawalReducer.actions;
export default withdrawalReducer.reducer;
