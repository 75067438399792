import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RiskLevel } from "@portal/types/robot/IRobotEnums";
import { PortalData } from "@portal/assets/values/Values";
import { ISavingAgreementAdditionalFunds } from "@portal/types/robot/ISavingAgreementAdditionalFunds";
import { ISavingAgreementData } from "@portal/types/robot/ISavingAgreementData";
import { ISavingAgreement } from "@portal/types/robot/ISavingAgreement";

const savingAgreementData = {
  riskLevel: null,
  oneTimeDeposit: PortalData.DEFAULT_ONE_TIME_DEPOSIT,
  monthlyAmount: PortalData.DEFAULT_MONTHLY_AMOUNT,
  sustainability: null,
  numOfYears: PortalData.DEFAULT_YEARS,
  numOfcalculationsEachYear: PortalData.DEFAULT_NUM_CALC_EACH_YEAR,
  numOfMonthsStartupAmount: PortalData.DEFAULT_MONTHS_STARTUP_AMOUNT,
  savingAgreementAdditionalFunds: [] as ISavingAgreementAdditionalFunds[],
} as ISavingAgreementData;

const initialState = {
  customerNumber: "",
  savingAgreementData,
} as ISavingAgreement;

export const savingProposalReducer = createSlice({
  name: "savingProposal",
  initialState,
  reducers: {
    setCustomerNumber: (state, action: PayloadAction<string>) => {
      state.customerNumber = action.payload;
    },
    setAgreementSustainability: (state, action: PayloadAction<boolean>) => {
      state.savingAgreementData.sustainability = action.payload;
    },
    setAgreementRiskLevel: (state, action: PayloadAction<RiskLevel | null>) => {
      state.savingAgreementData.riskLevel = action.payload;
    },
    setAgreementMonthlyAmount: (state, action: PayloadAction<number>) => {
      state.savingAgreementData.monthlyAmount = action.payload;
    },
    setAgreementOneTimeDeposit: (state, action: PayloadAction<number>) => {
      state.savingAgreementData.oneTimeDeposit = action.payload;
    },
    setAgreementNumOfMonthsStartupAmount: (state, action: PayloadAction<number>) => {
      state.savingAgreementData.numOfMonthsStartupAmount = action.payload;
    },
    setAgreementNumOfYears: (state, action: PayloadAction<number>) => {
      state.savingAgreementData.numOfYears = action.payload;
    },
    clearSavingProposalData: state => {
      state.customerNumber = "";
      state.savingAgreementData = savingAgreementData;
    },
  },
});

export const {
  setAgreementSustainability,
  setAgreementRiskLevel,
  setAgreementMonthlyAmount,
  setAgreementOneTimeDeposit,
  setAgreementNumOfMonthsStartupAmount,
  setAgreementNumOfYears,
  setCustomerNumber,
  clearSavingProposalData,
} = savingProposalReducer.actions;
export default savingProposalReducer.reducer;
