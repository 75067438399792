import { ADVISOR_REFRESH_ACCESS_TOKEN_ERROR, USER_REFRESH_ACCESS_TOKEN_ERROR } from "@portal/helpers/errorConstants";
import { signIn, useSession } from "next-auth/react";
import { useEffect } from "react";

const VerifySession = () => {
  const { data: session } = useSession();
  useEffect(() => {
    if (session?.error === USER_REFRESH_ACCESS_TOKEN_ERROR) {
      signIn("identity-server4", { callbackUrl: window.location.pathname });
    }
    if (session?.error === ADVISOR_REFRESH_ACCESS_TOKEN_ERROR) {
      signIn("internal-identity-server4", { callbackUrl: window.location.pathname });
    }
  }, [session]);
};

export default VerifySession;
