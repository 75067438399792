export const Size = {
  ACCOUNTS_WIDTH: 240,

  PORTFOLIOS_WIDTH: 330,
  PORTFOLIOS_HEIGHT: 400,

  WIDGET_WIDTH: 265,
  WIDGET_HEIGHT: 130,

  MODULE_MARGIN: 20,
  MODULE_PADDING: 15,

  BREAKPOINT_LARGE: 1280,
  BREAKPOINT_MEDIUM: 1024,
  BREAKPOINT_MOBILE: 767,

  INPUT_HEIGHT: 2.2, // rem
};

export const PortalData = {
  DEFAULT_MONTHLY_AMOUNT: 1000,
  MINIMUM_MONTHLY_AMOUNT: 0, // TODO skal tilbake til 250, men midlertidig satt til 0 for re-regisering hos sparebanken møre
  DEFAULT_ONE_TIME_DEPOSIT: 0,
  MINIMUM_ONE_TIME_DEPOSIT: 250,
  DEFAULT_YEARS: 25,
  DEFAULT_MONTHS_STARTUP_AMOUNT: 1,
  DEFAULT_NUM_CALC_EACH_YEAR: 3,
};

export const RobotData = {
  PAGES_TOTAL: 17,
};

export const RobotSize = {
  APP_WIDTH: 750,
  BREAKPOINT_MOBILE: 414,
  BREAKPOINT_TABLET: 767,
  HEADER_HEIGHT: 100,
  HEADER_HEIGHT_STARTPAGE: 50,
  HEADER_HEIGHT_MOBILE: 90,
};
