import Typography from "@portal/components/atoms/typography";

interface Props {
  count: number | undefined;
  showNotifications: boolean;
  color?: string;
  small?: boolean;
}

/**
 * Notification item to display a number in a circle.
 * @param interface
 * @returns a colored circle with a count in white text.
 */
export const NotificationItem = ({ count, showNotifications, color = "bg-error", small = false }: Props) => {
  const variant = count && count > 9 ? "DescriptionSmall" : "Description";
  const showStaticNumber = count && count > 99;
  const staticNumber = "99+";
  const isLarge = !small || showStaticNumber;

  return (
    <div className="flex space-x-3 grow pl-1 z-0">
      <Typography
        variant={variant}
        className={`flex ${color} text-white items-center justify-center rounded-full mr-1 ${isLarge ? "w-5 h-5" : "w-4 h-4"}   ${
          showNotifications ? "" : "hidden"
        }`}
      >
        {!showStaticNumber ? count : staticNumber}
      </Typography>
    </div>
  );
};
