// Norne
import norneLightLogo from "./svg/norne/norne-logo-light.svg";
import norneBlueLogo from "./svg/norne/norne-logo-blue.svg";
// Sparebanken Vest
import sparebankenVestLogo from "./svg/sparebankenVest/logo.svg";
// Fana Sparebank
import fanaSparebankLogo from "./svg/fanaSparebank/logo.svg";
// Voss Sparebank
import vossSparebankLogo from "./svg/vossSparebank/logo.svg";
// Sparebanken Møre
import sparebankenMøreLogo from "./svg/sparebankenMøre/logo.svg";
// Sparebanken Øst
import sparebankenØstLogo from "./svg/sparebankenØst/logo.svg";
// Flekkefjord Sparebank
import flekkefjordSparebankLogo from "./svg/flekkefjordSparebank/logo.svg";
// Haugesund Sparebank
import haugesundSparebankLogo from "./svg/haugesundSparebank/logo.svg";
// Helgeland Sparebank
import helgelandSparebankLogo from "./svg/helgelandSparebank/logo.svg";
// Lillesand Sparebank
import lillesandSparebankLogo from "./svg/lillesandSparebank/logo.svg";
// Luster Sparebank
import lusterSparebankLogo from "./svg/lusterSparebank/logo.svg";
// OBOS
import obosBankLogo from "./svg/obos/logo.svg";
// Spareskillingsbanken
import spareSkillingsbankenLogo from "./svg/spareSkillingsBanken/logo.svg";
// Cultura
import culturaLogo from "./svg/cultura/logo.svg";
// Skudenes & Aakra Sparebank
import skudenesOgAaakraSparebankLogo from "./svg/skudenesOgAakraSparebank/logo.svg";
// Søgne og Greipstad Sparebank
import søgneOgGreipstadSparebankLogo from "./svg/søgneOgGreipstadSparebank/logo.svg";
// Grue sparebank
import grueSparebankLogo from "./svg/grueSparebank/logo.svg";
// Sparebanken Sør
import spareBankenSørLogo from "./svg/sparebankenSør/logo.svg";
// Etne Sparebank
import etneSparebankLogo from "./svg/etneSparebank/logo.svg";
// Sogn Sparebank
import sognSparebankLogo from "./svg/sognSparebank/logo.svg";
// Eika
import eikaLogo from "./svg/eikaKapitalforvaltning/logo.svg";
// Sparebanken Sogn og Fjordane
import sparebankenSognOgFjordaneLogo from "./svg/sparebankenSognOgFjordane/logo.svg";
// Eidsberg Sparebank
import eidsbergSparebankLogo from "./svg/eidsbergSparebank/logo.svg";
// Valdres Sparebank
import valdresSparebankLogo from "./svg/valdresSparebank/logo.svg";
// Tysnes Sparebank
import tysnesSparebankLogo from "./svg/tysnesSparebank/logo.svg";
// Strømmen Sparebank
import strømmenSparebankLogo from "./svg/strømmenSparebank/logo.svg";
// Narvik Sparebank
import sparebankenNarvikLogo from "./svg/sparebankenNarvik/logo.svg";
// Valle Sparebank
import valleSparebankLogo from "./svg/valleSparebank/logo.svg";
// Trøgstad Sparebank
import trøgstadSparebankLogo from "./svg/trøgstadSparebank/logo.svg";
// Totens Sparebank
import totensSparebankLogo from "./svg/totensSparebank/logo.svg";
// Sunndal Sparebank
import sunndalSparebankLogo from "./svg/sunndalSparebank/logo.svg";
// Stadsbygd Sparebank
import statsbygdSparebankLogo from "./svg/stadsbygdSparebank/logo.svg";
// Skagerak Sparebank
import skagerakSparebankLogo from "./svg/skagerakSparebank/logo.svg";
// Bien Sparebank
import bienSparebankLogo from "./svg/bienSparebank/logo.svg";
// Skue Sparebank
import skueSparebankLogo from "./svg/skueSparebank/logo.svg";
// Soknedal Sparebank
import soknedalSparebankLogo from "./svg/soknedalSparebank/logo.svg";
// Hemne Sparebank
import hemneSparebankLogo from "./svg/hemneSparebank/logo.svg";
// Trøndelag Sparebank
import trøndelagSparebankLogo from "./svg/trøndelagSparebank/logo.svg";
// Åfjord Sparebank
import åfjordSparebankLogo from "./svg/åfjordSparebank/logo.svg";
// Setskog Sparebank
import setskogSparebankLogo from "./svg/setskogSparebank/logo.svg";
// Selbu Sparebank
import selbuSparebankLogo from "./svg/selbuSparebank/logo.svg";
// Oslofjord Sparebank
import oslofjordSparebankLogo from "./svg/osloFjordSparebank/logo.svg";
// Rindal Sparebank
import rindalSparebankLogo from "./svg/rindalSparebank/logo.svg";
// Romerike Sparebank
import romerikeSparebankLogo from "./svg/romerikeSparebank/logo.svg";
// Romsdalsbanken
import romsdalsbankenLogo from "./svg/romsdalsBanken/logo.svg";
// Odal Sparebank
import odalSparebankLogo from "./svg/odalSparebank/logo.svg";
// Oppdalsbanken
import oppdalsbankenLogo from "./svg/oppdalsBanken/logo.svg";
// Ørskog Sparebank
import ørskogSparebankLogo from "./svg/ørskogSparebank/logo.svg";
// 68 grader nord
import sekstiÅtteGraderNordLogo from "./svg/68GraderNord/logo.svg";
// Asen Sparebank
import aasenSparebankLogo from "./svg/aasenSparebank/logo.svg";
// Andebu Sparebank
import andebuSparebankLogo from "./svg/andebuSparebank/logo.svg";
// Askim og Spydeberg Sparebank
import askimOgSpydebergSparebankLogo from "./svg/askimOgSpydebergSparebank/logo.svg";
// Aurskog Sparebank
import aurskogSparebankLogo from "./svg/aurskogSparebank/logo.svg";
// Berg Sparebank
import bergSparebankLogo from "./svg/bergSparebank/logo.svg";
// Birkenes Sparebank
import birkenesSparebankLogo from "./svg/birkenesSparebank/logo.svg";
// Bjugn Sparebank
import bjugnSparebankLogo from "./svg/bjugnSparebank/logo.svg";
// Drangedal Sparebank
import drangedalSparebankLogo from "./svg/drangedalSparebank/logo.svg";
// Etnedal Sparebank
import etnedalSparebankLogo from "./svg/etnedalSparebank/logo.svg";
// Fjaler Sparebank
import fjalerSparebankLogo from "./svg/fjalerSparebank/logo.svg";
// Grong Sparebank
import grongSparebankLogo from "./svg/grongSparebank/logo.svg";
// Haltdalen Sparebank
import haltdalenSparebankLogo from "./svg/haltdalenSparebank/logo.svg";
// Hegra Sparebank
import hegraSparebankLogo from "./svg/hegraSparebank/logo.svg";
// Kvinesdal Sparebank
import kvinesdalSparebankLogo from "./svg/kvinesdalSparebank/logo.svg";
// Melhusbanken
import melhusBankenLogo from "./svg/melhusBanken/logo.svg";
// Hjartdalbanken
import hjartdalBankenLogo from "./svg/hjartdalBanken/logo.svg";
// Hjelmeland Sparebank
import hjelmelandSparebankLogo from "./svg/hjelmelandSparebank/logo.svg";
// Marker Sparebank
import markerSparebankLogo from "./svg/markerSparebank/logo.svg";
// Nidaros Sparebank
import nidarosSparebankLogo from "./svg/nidarosSparebank/logo.svg";
// Høland Sparebank
import hølandSparebankLogo from "./svg/hølandSparebank/logo.svg";
// Ørland Sparebank
import ørlandSparebankLogo from "./svg/ørlandSparebank/logo.svg";
// Larvikbanken
import larvikBankenLogo from "./svg/larvikBanken/logo.svg";
// Jernbanepersonalets Bank
import jernbanePersonaletsBankLogo from "./svg/jernbanePersonaletsBank/logo.svg";
// Jæren sparebank
import jærenSparebankLogo from "./svg/jærenSparebank/logo.svg";
// Tinn Sparebank
import tinnSparebankLogo from "./svg/tinnSparebank/logo.svg";
// Agder Sparebank
import agderSparebankLogo from "./svg/agderSparebank/logo.svg";
// Evje & Hornes Sparebank
import evjeOgHornesSparebankLogo from "./svg/evjeOgHornesSparebank/logo.svg";
// Norse Securities
import norseSecuritiesLogo from "./svg/norseSecurities/logo.svg";
// Orkla Sparebank
import orklaSparebankLogo from "./svg/orklaSparebank/logo.svg";
// Røros Sparebank
import rørosBankenLogo from "./svg/rørosBanken/logo.svg";
// Surnadal Sparebank
import surnadalSparebankLogo from "./svg/surnadalSparebank/logo.svg";
// Tolga-os Sparebank
import tolgaOsSparebankLogo from "./svg/tolgaOsSparebank/logo.svg";
// Vibrand
import vibrandLogo from "./svg/vibrand/logo.svg";
// Sparebanken DIN
import spareBankenDinLogo from "./svg/sparebankenDin/logo.svg";

const Logos = {
  norneLightLogo,
  norneBlueLogo,
  sparebankenVestLogo,
  sparebankenMøreLogo,
  fanaSparebankLogo,
  vossSparebankLogo,
  sparebankenØstLogo,
  flekkefjordSparebankLogo,
  haugesundSparebankLogo,
  helgelandSparebankLogo,
  lillesandSparebankLogo,
  lusterSparebankLogo,
  obosBankLogo,
  spareSkillingsbankenLogo,
  culturaLogo,
  skudenesOgAaakraSparebankLogo,
  søgneOgGreipstadSparebankLogo,
  grueSparebankLogo,
  spareBankenSørLogo,
  etneSparebankLogo,
  sognSparebankLogo,
  eikaLogo,
  sparebankenSognOgFjordaneLogo,
  eidsbergSparebankLogo,
  valdresSparebankLogo,
  tysnesSparebankLogo,
  strømmenSparebankLogo,
  sparebankenNarvikLogo,
  valleSparebankLogo,
  trøgstadSparebankLogo,
  totensSparebankLogo,
  sunndalSparebankLogo,
  statsbygdSparebankLogo,
  skagerakSparebankLogo,
  bienSparebankLogo,
  skueSparebankLogo,
  soknedalSparebankLogo,
  hemneSparebankLogo,
  trøndelagSparebankLogo,
  åfjordSparebankLogo,
  setskogSparebankLogo,
  selbuSparebankLogo,
  oslofjordSparebankLogo,
  rindalSparebankLogo,
  romerikeSparebankLogo,
  romsdalsbankenLogo,
  odalSparebankLogo,
  oppdalsbankenLogo,
  ørskogSparebankLogo,
  sekstiÅtteGraderNordLogo,
  aasenSparebankLogo,
  andebuSparebankLogo,
  askimOgSpydebergSparebankLogo,
  aurskogSparebankLogo,
  bergSparebankLogo,
  birkenesSparebankLogo,
  bjugnSparebankLogo,
  drangedalSparebankLogo,
  etnedalSparebankLogo,
  fjalerSparebankLogo,
  grongSparebankLogo,
  haltdalenSparebankLogo,
  hegraSparebankLogo,
  kvinesdalSparebankLogo,
  melhusBankenLogo,
  hjartdalBankenLogo,
  hjelmelandSparebankLogo,
  markerSparebankLogo,
  nidarosSparebankLogo,
  hølandSparebankLogo,
  ørlandSparebankLogo,
  larvikBankenLogo,
  jernbanePersonaletsBankLogo,
  jærenSparebankLogo,
  tinnSparebankLogo,
  agderSparebankLogo,
  evjeOgHornesSparebankLogo,
  norseSecuritiesLogo,
  orklaSparebankLogo,
  rørosBankenLogo,
  surnadalSparebankLogo,
  tolgaOsSparebankLogo,
  vibrandLogo,
  spareBankenDinLogo,
} as const;

export default Logos;
