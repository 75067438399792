export const handleForbiddenError = async (response: Response): Promise<string | undefined> => {
  if (response.status === 403) {
    try {
      const respText = await response.text();
      return handleForbiddenErrorText(respText);
    } catch (error) {
      console.error(error);
    }
  }
  return undefined;
};

export const handleForbiddenErrorText = (errorText: string): string | undefined => {
  if (errorText.includes("Advisor does not have write access to portfolio")) {
    return "Rådgiver har ikke skrivetilgang";
  }
  if (errorText.includes("Customer is not over 18 years old")) {
    return "Du må være over 18 år for å gjøre denne handlingen";
  }
  return undefined;
};
