import BankLogo from "../logos";

interface Props {
  centerId: string;
}

const BankLogoComponent = ({ centerId }: Props) => (
  <div className="flex mr-4 max-h-[60px] min-w-[140px] max-w-[210px]">
    <BankLogo centerId={centerId} />
  </div>
);

export default BankLogoComponent;
