import { IModifySavingsAgreementRequest } from "@portal/types/api/IModifySavingsAgreementRequest";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const updateSavingsAgreement = createAsyncThunk("updateSavingsAgreement", async (req: IModifySavingsAgreementRequest) => {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...req.modifySavingsAgreement,
      dateTo: req.modifySavingsAgreement.dateTo ? req.modifySavingsAgreement.dateTo : "3000-01-01",
      monthTable: req.modifySavingsAgreement.monthTable.join(""),
      periodDayNumber:
        req.modifySavingsAgreement.periodType === "M"
          ? req.modifySavingsAgreement.periodDayNumber
          : new Date(req.modifySavingsAgreement.nextDate).getDate(),
    }),
  };
  const url = `/api/updateSavingsAgreement?portfolioId=${req.portfolioId}&savingsAgreementId=${req.savingsAgreementId}`;

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw Error(response.statusText);
  }
});
