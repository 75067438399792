import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInfrontStockItem, InfrontAllStocksState, IUpdateStockItem, UpdateStockField } from "./types";

const initialState = {
  allStocks: [],
  status: "idle",
  error: null,
} as InfrontAllStocksState;

const emptyStock = {
  index: -1,
  feed: -1,
  ticker: "",
  isin: "",
  name: "",
  flag: "",
  last: 0,
  yesterdayClose: 0,
  lastValidDate: "",
} as IInfrontStockItem;

const updateStock = (state: InfrontAllStocksState, action: PayloadAction<IUpdateStockItem>, field: UpdateStockField) => {
  const stock = state.allStocks.find(o => o.index === action.payload.index && o.feed === action.payload.feed);
  if (stock) {
    stock[field] = action.payload.value as string;
  } else {
    state.allStocks.push({
      ...emptyStock,
      index: action.payload.index,
      feed: action.payload.feed,
      [field]: action.payload.value,
    });
  }
};

export const infrontAllStocks = createSlice({
  name: "infront",
  initialState,
  reducers: {
    setTicker: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "ticker");
    },
    setIsin: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "isin");
    },
    setName: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "name");
    },
    setFlag: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "flag");
    },
    setLast: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "last");
    },
    setYesterdayClose: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "yesterdayClose");
    },
    setLastValidDate: (state, action: PayloadAction<IUpdateStockItem>) => {
      updateStock(state, action, "lastValidDate");
    },
  },
});
export const { setTicker, setIsin, setName, setFlag, setLast, setYesterdayClose, setLastValidDate } = infrontAllStocks.actions;
export default infrontAllStocks.reducer;
