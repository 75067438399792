import { PoaUser } from "iron-session";
import useSWR, { SWRResponse } from "swr";
import { IUseSelectedPowerOfAttorney } from "./types";
import { useMemo } from "react";
import usePowerOfAttorneyLoggedInUser from "../usePowerOfAttorneyLoggedInUser";
import commonSwrConfig from "../commonSwrConfig";

const useSelectedPowerOfAttorney: IUseSelectedPowerOfAttorney = () => {
  //const router = useRouter();

  //const [invalidPoaUser, setInvalidPoaUser] = useState<boolean>(false);
  const { data: powerOfAttorneys /*, isLoading: poaIsLoading, error: poaError*/ } = usePowerOfAttorneyLoggedInUser();

  const fetcher = async (url: URL) => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };
  const { data, error, isLoading }: SWRResponse<PoaUser, unknown> = useSWR("/api/poaUser", fetcher, commonSwrConfig());

  const deletePoa = async () => {
    const response = await fetch("/api/poaUser", {
      method: "DELETE",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return;
  };

  // useEffect(() => {
  //   if (data?.userId && !poaIsLoading && !poaError) {
  //     if (!powerOfAttorneys?.some(p => p.contactInfo.customerNumber === data?.userId)) {
  //       setInvalidPoaUser(true);
  //       router.push("/handleInvalidSession");
  //     }
  //   }
  //   setInvalidPoaUser(false);
  // }, [data, poaIsLoading, poaError, powerOfAttorneys, router]);

  const selectedUser = useMemo(() => {
    if (
      powerOfAttorneys &&
      powerOfAttorneys.length > 0 &&
      data?.userId &&
      powerOfAttorneys.some(p => p.customerNumber === data?.userId)
    ) {
      return data;
    }
    return undefined;
  }, [data, powerOfAttorneys]);

  return {
    data: selectedUser,
    deletePoa,
    //invalidPoaUser,
    isLoading,
    error,
  };
};

export default useSelectedPowerOfAttorney;
