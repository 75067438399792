import { TextResources } from "@portal/assets/text/TextResources";
import { RootState } from "@portal/redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sendMessage } from "../../api/sendMessage/reducer";
import { IFile, INewMessage, INewMessageFormData } from "./types";

const initialState = {
  formData: {
    subject: "",
    text: "",
    attachments: [],
  } as INewMessageFormData,
  subjectError: "",
  messageError: "",
  fileError: "",
  apiError: "",
  showCompleteMessage: false,
} as INewMessage;

const validateAttachmentsTotalSize = (attachments: IFile[]) => {
  return attachments.reduce((size, attachment) => {
    return size + attachment.size;
  }, 0) > 104857600
    ? TextResources.MAILBOX_FILE_SIZE_ERROR
    : "";
};

export const uiSendMessage = createAsyncThunk("uiSendMessage", async (_, thunkAPI) => {
  thunkAPI.dispatch(validateForm());
  const state = thunkAPI.getState() as RootState;
  const message = state.other.ui.newMessage;
  if (message.subjectError || message.messageError || message.fileError) {
    return;
  }
  thunkAPI.dispatch(sendMessage());
});

export const newMessageReducer = createSlice({
  name: "newMessage",
  initialState,
  reducers: {
    setSubject: (state, action: PayloadAction<string>) => {
      state.formData.subject = action.payload;
    },
    setText: (state, action: PayloadAction<string>) => {
      state.formData.text = action.payload;
    },
    addFile: (state, action: PayloadAction<IFile>) => {
      state.formData.attachments.push(action.payload);
      state.fileError = validateAttachmentsTotalSize(state.formData.attachments);
    },
    deleteFile: (state, action: PayloadAction<string>) => {
      state.formData.attachments = state.formData.attachments.filter(file => file.id !== action.payload);
      state.fileError = validateAttachmentsTotalSize(state.formData.attachments);
    },
    setSubjectError: (state, action: PayloadAction<string>) => {
      state.subjectError = action.payload;
    },
    setFileError: (state, action: PayloadAction<string>) => {
      state.fileError = action.payload;
    },
    setMessageError: (state, action: PayloadAction<string>) => {
      state.messageError = action.payload;
    },
    setApiError: (state, action: PayloadAction<string>) => {
      state.apiError = action.payload;
    },
    validateForm: state => {
      state.subjectError = state.formData.subject === "" ? TextResources.MAILBOX_SUBJECT_MISSING : "";
      state.messageError = state.formData.text === "" ? TextResources.MAILBOX_MESSAGE_MISSING : "";
      state.fileError = validateAttachmentsTotalSize(state.formData.attachments);
    },
    validateText: state => {
      state.messageError = state.formData.text === "" ? TextResources.MAILBOX_MESSAGE_MISSING : "";
    },
    validateSubject: state => {
      state.subjectError = state.formData.subject === "" ? TextResources.MAILBOX_SUBJECT_MISSING : "";
    },
    clearShowCompleteMessage: state => {
      state.showCompleteMessage = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sendMessage.fulfilled, state => {
        state.formData = initialState.formData;
        state.subjectError = "";
        state.messageError = "";
        state.apiError = "";
        state.fileError = "";
        state.showCompleteMessage = true;
      })
      .addCase(sendMessage.rejected, state => {
        state.apiError = TextResources.MAILBOX_GENERAL_SEND_MESSAGE_ERROR;
      });
  },
});

export const {
  setSubject,
  setText,
  addFile,
  setFileError,
  setSubjectError,
  setApiError,
  deleteFile,
  setMessageError,
  validateForm,
  validateText,
  validateSubject,
  clearShowCompleteMessage,
} = newMessageReducer.actions;
export default newMessageReducer.reducer;
