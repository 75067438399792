import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPositions, setPositions } from "./api/positions/reducer";

export const runInitialRequests = createAsyncThunk("app/runInitialRequests", async (centerId: string, thunkAPI) => {
  await thunkAPI.dispatch(fetchPositions({ registerStockObserver: true, centerId }));
});

export const reInitialized = createAsyncThunk("app/reInitialized", async (centerId: string, thunkAPI) => {
  await thunkAPI.dispatch(setPositions([]));
  // TODO hvis vi skal kjøre clearOrders,
  // må vi også ha en feed re-connect. usikker på om det er en god ide å gjøre fra flere steder
  // evt må vi trigge useFeedHubStreams
  // await thunkAPI.dispatch(clearOrders());
  await thunkAPI.dispatch(runInitialRequests(centerId));
});
