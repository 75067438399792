import styled from "styled-components";
import { IBankLogoContainer } from "./types";

export const BankLogoContainer = styled.div<IBankLogoContainer>`
  svg {
    * {
      color: ${({ color }) => color};
    }
  }
`;
