import { MenuButton } from "@headlessui/react";
import { IMessagesToCustomerResponse } from "@portal/types/api/mailbox/IMessagesToCustomerResponse";
import { AvatarComponent } from "@portal/components/atoms/avatar";
import { NotificationItem } from "@portal/components/atoms/notificationItem";

interface Props {
  totalMessages: IMessagesToCustomerResponse | undefined;
  showNotifications: boolean;
  userName: string;
  corporateUser: boolean;
}

/**
 * Usermenu in the top header
 * @param interface
 * @returns a button with the username, an icon and count of unread messages.
 */
export const HeaderMenu = ({ totalMessages, showNotifications, userName, corporateUser }: Props) => (
  <MenuButton className="flex items-center justify-center">
    <div className={`flex ${showNotifications ? `mr-[-20px]` : `mr-[-9px]`}`}>
      <AvatarComponent text={userName} corporateUser={corporateUser} userAvatar />
    </div>
    <div className="flex items-center mt-[-22px]">
      <NotificationItem count={totalMessages?.totalUnreadMessages} showNotifications={showNotifications} />
    </div>
  </MenuButton>
);
