import { IMessagesToCustomerResponse } from "@portal/types/api/mailbox/IMessagesToCustomerResponse";
import useSWR, { SWRResponse } from "swr";
import { IUseTotalUnreadMessages } from "./types";
import useAdvisor from "../useAdvisor";

const useTotalUnreadMessages: IUseTotalUnreadMessages = (revalidate = false) => {
  const { isAdvisor } = useAdvisor();

  const fetcher = async (url: URL) => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };
  const { data, error, isLoading }: SWRResponse<IMessagesToCustomerResponse, unknown> = useSWR(
    !isAdvisor ? `/api/mailbox/toMessages?limit=1&offset=0` : null,
    fetcher,
    {
      revalidateIfStale: revalidate,
      revalidateOnFocus: revalidate,
      revalidateOnReconnect: revalidate,
    },
  );

  return {
    data,
    isLoading,
    error,
  };
};

export default useTotalUnreadMessages;
