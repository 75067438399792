import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IModifySavingsAgreement } from "@portal/types/api/IModifySavingsAgreement";
import { IModifySavingsAgreementFund } from "@portal/types/api/IModifySavingsAgreementFund";
import { updateSavingsAgreement } from "../../api/updateSavingsAgreement/reducer";

export interface IFundAmount {
  isin: string;
  amount: number;
}

const initialRequest = {
  adjustmentComment: undefined,
  advisorCarriedOut: false,
  advisorName: "",
  cashAccount: null,
  periodDayNumber: undefined,
  dateFrom: undefined,
  nextDate: "",
  periodType: "NOT_SET",
  dateTo: null,
  monthTable: [],
  savingsAgreementDescription: "",
  funds: [],
  isSavingRobot: false,
} as IModifySavingsAgreement;

const initialResponse = {
  success: false,
};

const initialState = {
  request: initialRequest,
  response: initialResponse,
  isLoading: false,
  hasError: false,
};

export const updateSavingsAgreementReducer = createSlice({
  name: "updateSavingsAgreement",
  initialState,
  reducers: {
    setUpdateSavingsAgreementMonthTable: (state, action: PayloadAction<number[]>) => {
      state.request.monthTable = action.payload;
    },
    setUpdateSavingsAgreementAdjustmentComment: (state, action: PayloadAction<string>) => {
      state.request.adjustmentComment = action.payload;
    },
    setUpdateSavingsAgreementAdvisorCarriedOut: (state, action: PayloadAction<boolean>) => {
      state.request.advisorCarriedOut = action.payload;
    },
    setUpdateSavingsAgreementAdvisorName: (state, action: PayloadAction<string>) => {
      state.request.advisorName = action.payload;
    },
    setUpdateSavingsAgreementPeriodDayNumber: (state, action: PayloadAction<number>) => {
      state.request.periodDayNumber = action.payload;
    },
    setUpdateSavingsAgreementPeriodType: (state, action: PayloadAction<string>) => {
      state.request.periodType = action.payload;
    },
    setUpdateSavingsAgreementStartDate: (state, action: PayloadAction<string>) => {
      state.request.dateFrom = action.payload;
    },
    setUpdateSavingsAgreementEndDate: (state, action: PayloadAction<string | null>) => {
      state.request.dateTo = action.payload;
    },
    setUpdateSavingsAgreementNextDate: (state, action: PayloadAction<string>) => {
      state.request.nextDate = action.payload;
    },
    addUpdateSavingsAgreementFund: (state, action: PayloadAction<IModifySavingsAgreementFund>) => {
      if (state.request.funds.some(f => f.isin === action.payload.isin)) return;
      else state.request.funds = [...state.request.funds, action.payload];
    },
    removeUpdateSavingsAgreementFund: (state, action: PayloadAction<string>) => {
      state.request.funds = state.request.funds.filter(fund => fund.isin !== action.payload);
    },
    removeUpdateSavingsAgreementFunds: state => {
      state.request.funds = [];
    },
    setUpdateSavingsAgreementFundAmount: (state, action: PayloadAction<IFundAmount>) => {
      const { amount } = action.payload;

      state.request.funds.forEach(fund => {
        if (fund.isin === action.payload.isin) {
          state.request.funds = state.request.funds.map(f => (f.isin === action.payload.isin ? { ...f, amount } : f));
        }
      });
    },
    setUpdateSavingsAgreementFunds: (state, action: PayloadAction<IModifySavingsAgreementFund[]>) => {
      state.request.funds = action.payload;
    },
    setUpdateSavingsAgreementCashAccount: (state, action: PayloadAction<string>) => {
      state.request.cashAccount = action.payload;
    },
    clearUpdateSavingsAgreementData: state => {
      state.request = initialRequest;
      state.response.success = false;
      state.hasError = false;
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateSavingsAgreement.pending, state => {
        state.isLoading = true;
        state.hasError = false;
        state.response = { success: false };
      })
      .addCase(updateSavingsAgreement.fulfilled, state => {
        state.isLoading = false;
        state.hasError = false;
        state.response = { success: true };
      })
      .addCase(updateSavingsAgreement.rejected, state => {
        state.isLoading = false;
        state.hasError = true;
        state.response = { success: false };
      });
  },
});

export const {
  setUpdateSavingsAgreementAdjustmentComment,
  addUpdateSavingsAgreementFund,
  removeUpdateSavingsAgreementFund,
  removeUpdateSavingsAgreementFunds,
  setUpdateSavingsAgreementFunds,
  setUpdateSavingsAgreementAdvisorCarriedOut,
  setUpdateSavingsAgreementAdvisorName,
  setUpdateSavingsAgreementMonthTable,
  setUpdateSavingsAgreementCashAccount,
  setUpdateSavingsAgreementPeriodDayNumber,
  setUpdateSavingsAgreementPeriodType,
  setUpdateSavingsAgreementStartDate,
  setUpdateSavingsAgreementEndDate,
  setUpdateSavingsAgreementNextDate,
  setUpdateSavingsAgreementFundAmount,
  clearUpdateSavingsAgreementData,
} = updateSavingsAgreementReducer.actions;
export default updateSavingsAgreementReducer.reducer;
