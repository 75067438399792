import { TextResources } from "@portal/assets/text/TextResources";
import PortalPageBox from "@portal/components/atoms/portalPageBox";
import { TextLine } from "@portal/components/atoms/textLine";
import Typography from "@portal/components/atoms/typography";
import LayoutNotVerifiedAml from "@portal/components/organisms/Layout-NotVerifiedAml";

const GeneralAmlErrorPage = () => (
  <LayoutNotVerifiedAml>
    <PortalPageBox>
      <div>
        <Typography variant="H6">{TextResources.GENERAL_ERROR_TITLE}</Typography>
        <TextLine />
      </div>
      <Typography variant="Body">{TextResources.GENERAL_ERROR_DESCRIPTION}</Typography>
    </PortalPageBox>
  </LayoutNotVerifiedAml>
);

export default GeneralAmlErrorPage;
