import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { orderUpdate, loadingComplete } from "@portal/redux/reducers/api/orders/reducer";
import { positionUpdate } from "@portal/redux/reducers/api/positions/reducer";
import { IOrder } from "@portal/types/api/IOrder";
import { IPositionUpdate } from "@portal/types/api/IPositionUpdate";
import { registerStockObserverForOrder } from "@portal/infront/stockData";
import { handlePositionUpdate } from "@portal/redux/reducers/api/handlePositionUpdate/reducer";
import { AppDispatch } from "@portal/redux/store";
import { trackMessage } from "@portal/appInsights";

const streamOrdersHandler = (dispatch: AppDispatch) => ({
  next: async (item: IOrder) => {
    if (item.tickerCode === "Du har ingen ordre") {
      dispatch(loadingComplete());
    } else {
      if (item.orderLimit && item.orderLimit > 0) {
        registerStockObserverForOrder(item, dispatch);
      }
      dispatch(orderUpdate(item));
    }
  },
  complete: () => {
    console.log("complete");
  },
  error: (err: unknown) => {
    console.error("err", err);
  },
});

const streamPositionsHandler = (dispatch: AppDispatch, centerId: string) => ({
  next: (item: IPositionUpdate) => {
    dispatch(positionUpdate(item));
    dispatch(handlePositionUpdate(centerId));
  },
  complete: () => {
    console.log("complete");
  },
  error: (err: unknown) => {
    console.error("err", err);
  },
});

export const feedHubConnection = (access_token: string) => {
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.NEXT_PUBLIC_FEED_BASE_URL}/feedhub?access_token=${access_token}`)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: retryContext => {
        trackMessage(`nextRetryDelayInMilliseconds called: ${JSON.stringify(retryContext)}`);
        if (retryContext.elapsedMilliseconds < 1800000) {
          if (retryContext.previousRetryCount < 3) {
            return 2 * 1000;
          }
          if (retryContext.previousRetryCount < 5) {
            return 5 * 1000;
          }
          if (retryContext.previousRetryCount < 10) {
            return 10 * 1000;
          }
          return 15 * 1000;
        } else {
          trackMessage(`Stop retrying after 30 minutes`);
          return null;
        }
      },
    })
    .configureLogging({
      log: (logLevel: LogLevel, message: string) => {
        if (logLevel > 1) {
          trackMessage(`signalR logging - logLevel: ${logLevel}, message: ${message}`);
        }
      },
    })
    .build();

  return connection;
};

export const streamOrders = async (
  connection: signalR.HubConnection | undefined,
  dispatch: AppDispatch,
  poaUser?: string,
  isAdvisor?: boolean,
) => {
  if (connection) {
    if (isAdvisor) {
      connection
        .stream<IOrder>("StreamOrdersWithAdvisor", poaUser ? poaUser : undefined)
        .subscribe(streamOrdersHandler(dispatch));
    } else if (poaUser) {
      connection.stream<IOrder>("StreamOrdersWithPoa", poaUser ? poaUser : undefined).subscribe(streamOrdersHandler(dispatch));
    } else {
      connection.stream<IOrder>("StreamOrders").subscribe(streamOrdersHandler(dispatch));
    }
  }
};

export const streamPositionUpdates = async (
  connection: signalR.HubConnection | undefined,
  dispatch: AppDispatch,
  centerId: string,
  poaUser?: string,
  isAdvisor?: boolean,
) => {
  if (connection) {
    if (isAdvisor) {
      connection
        .stream<IPositionUpdate>("StreamPositionUpdatesWithAdvisor", poaUser ? poaUser : "")
        .subscribe(streamPositionsHandler(dispatch, centerId));
    } else if (poaUser) {
      connection
        .stream<IPositionUpdate>("StreamPositionUpdatesWithPoa", poaUser ? poaUser : "")
        .subscribe(streamPositionsHandler(dispatch, centerId));
    } else {
      connection.stream<IPositionUpdate>("StreamPositionUpdates").subscribe(streamPositionsHandler(dispatch, centerId));
    }
  }
};
