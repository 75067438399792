import styled from "styled-components";

interface Props {
  small?: boolean;
  userAvatar?: boolean;
}

export const AvatarCircle = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.small ? 30 : 37)}px;
  width: ${props => (props.small ? 30 : 37)}px;
  background-color: ${props => (props.userAvatar ? "var(--color-inverseMenuBackground)" : "var(--color-primary)")};
  color: ${props => (props.userAvatar ? "var(--color-inverseMenuText)" : "var(--color-accountMenuText)")};
  border-radius: 50%;
  transition: background-color ease-in-out 100ms;

  :hover {
    background-color: ${props => props.userAvatar && "var(--color-menuHover)"};
  }
`;
