import { useSession } from "next-auth/react";
import useImpersonatedUser from "../useImpersonatedUser";
import { IUseAdvisor } from "./types";
import { isAdvisor } from "@portal/helpers/portalRoleStrings";

const useAdvisor: IUseAdvisor = () => {
  const { data: session, status } = useSession();
  const { data: impersonatedUser, isLoading } = useImpersonatedUser();
  const isAdvisorBool = isAdvisor(session?.role);

  const isAdvisorAndNoImpersonation = isAdvisorBool && !impersonatedUser?.customerNumber;

  return {
    isAdvisor: isAdvisorBool,
    isAdvisorAndNoImpersonation,
    impersonatedCustomerNumber: impersonatedUser?.customerNumber,
    isLoading: status === "loading" || isLoading,
  };
};

export default useAdvisor;
