import { ISuitabilityReport } from "@portal/types/robot/ISuitabilityReport";
import { ISuitabilityReportData } from "@portal/types/robot/ISuitabilityReportData";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createSuitabilityReport = createAsyncThunk("createSuitabilityReport", async (req: ISuitabilityReportData) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  const url = "/api/robot/SuitabilityReport/";
  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    console.error(response.statusText);
    return;
  }
  return (await response.json()) as ISuitabilityReport;
});
