import { handleForbiddenError } from "@portal/helpers/handleForbiddenError";
import { ICreatedSavingsAgreement } from "@portal/types/api/ICreatedSavingsAgreement";
import { ICreateSavingsAgreement } from "@portal/types/api/ICreateSavingsAgreement";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createSavingsAgreement = createAsyncThunk("createSavingsAgreement", async (req: ICreateSavingsAgreement) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...req,
      agreementData: {
        ...req.agreementData,
        dateTo: req.agreementData.dateTo ? req.agreementData.dateTo : "3000-01-01",
        monthTable: Array.isArray(req.agreementData.monthTable)
          ? req.agreementData.monthTable.join("")
          : req.agreementData.monthTable, // hack because of redux persist, monthTable was saved as string
        periodDayNumber:
          req.agreementData.periodType === "M"
            ? req.agreementData.periodDayNumber
            : new Date(req.agreementData.nextDate).getDate(),
      },
    }),
  };

  const url = "/api/savingsAgreement/";
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    const handledForbiddenError = await handleForbiddenError(response);
    throw Error(handledForbiddenError ?? "");
  }

  const savingsAgreement = (await response.json()) as ICreatedSavingsAgreement;

  if (req.oneTimeAmount) {
    await fetch("/api/savingsAgreement/onetimepayment", {
      method: "POST",
      headers: [
        ["accepts", "application/json"],
        ["Content-Type", "application/json"],
      ],
      body: JSON.stringify({
        portfolioId: req.portfolioId,
        savingsAgreementId: savingsAgreement.savingsAgreementId,
        amount: req.oneTimeAmount,
      }),
    });

    if (!response.ok) {
      // ignore error since agreement is created, else will not AvtaleGiro be created
    }
  }

  return savingsAgreement;
});
