import { feedHubConnection, streamOrders, streamPositionUpdates } from "@portal/api/clientStream/feedHub";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import useSelectedPowerOfAttorney from "../useSelectedPowerOfAttorney";
import useAdvisor from "../useAdvisor";
import { useAppDispatch } from "@portal/redux/store";
import { clearOrders } from "@portal/redux/reducers/api/orders/reducer";
import { trackMessage } from "@portal/appInsights";

const useFeedHubStreams = (centerId: string) => {
  const dispatch = useAppDispatch();
  const { data: session } = useSession();
  const { data: selectedUser, isLoading } = useSelectedPowerOfAttorney();
  const { isAdvisor, impersonatedCustomerNumber, isLoading: advisorIsLoading } = useAdvisor();
  useEffect(() => {
    let connection: signalR.HubConnection | undefined;
    if (session?.accessToken && !isLoading && !advisorIsLoading) {
      connection = feedHubConnection(session.accessToken);
      connection
        .start()
        .then(() => {
          // since this useeffect is called on every render, we need to clear the orders from last user
          dispatch(clearOrders());
          streamOrders(connection, dispatch, selectedUser?.userId || impersonatedCustomerNumber, isAdvisor);
          streamPositionUpdates(connection, dispatch, centerId, selectedUser?.userId || impersonatedCustomerNumber, isAdvisor);
        })
        .catch(err => console.error(err.toString()));

      connection.onreconnecting(err => {
        trackMessage(`Reconnecting to feedhub ${JSON.stringify(err)}`);
      });

      connection.onreconnected(() => {
        streamOrders(connection, dispatch, selectedUser?.userId || impersonatedCustomerNumber, isAdvisor);
        streamPositionUpdates(connection, dispatch, centerId, selectedUser?.userId || impersonatedCustomerNumber, isAdvisor);
      });

      return () => {
        connection?.stop();
      };
    }
  }, [
    session?.accessToken,
    dispatch,
    selectedUser,
    impersonatedCustomerNumber,
    isLoading,
    isAdvisor,
    advisorIsLoading,
    centerId,
  ]);
};

export default useFeedHubStreams;
