import Typography from "@portal/components/atoms/typography";
import router from "next/router";
import UserMenuItem from "./menuItem";
import useTotalUnreadMessages from "@portal/hooks/useTotalUnreadMessages";
import useSelectedPowerOfAttorney from "@portal/hooks/useSelectedPowerOfAttorney";
import useImpersonateUser from "@portal/hooks/useImpersonatedUser";
import featureFlags from "@portal/helpers/featureFlag";
import useIsCorporateUser from "@portal/hooks/useIsCorporateUser";
import useAdvisor from "@portal/hooks/useAdvisor";
import { FC, useState } from "react";
import { useSession } from "next-auth/react";
import { Menu, MenuItems } from "@headlessui/react";
import { TextResources } from "@portal/assets/text/TextResources";
import { HeaderMenu } from "./headerMenu";
import { SwitchUserItem } from "./switchUser";
import { NotificationItem } from "@portal/components/atoms/notificationItem";
import { IUserMenuProps } from "./types";
import usePowerOfAttorneyLoggedInUser from "@portal/hooks/usePowerOfAttorneyLoggedInUser";

export const UserMenu: FC<IUserMenuProps> = ({ amlVerified = true, centerId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: session } = useSession();
  const { data: totalMessages } = useTotalUnreadMessages();
  const { data: powerOfAttorneys } = usePowerOfAttorneyLoggedInUser();
  const { data: selectedUser } = useSelectedPowerOfAttorney();
  const { data: impersonatedUser, dropImpersonatedUser } = useImpersonateUser();
  const { isAdvisorAndNoImpersonation, isAdvisor } = useAdvisor();
  const { isCorporateUser } = useIsCorporateUser();
  const showNotifications =
    totalMessages?.totalUnreadMessages !== undefined && totalMessages?.totalUnreadMessages > 1 && amlVerified;
  const showSwitchUser = !isAdvisor && featureFlags.isPOAEnabled && powerOfAttorneys && powerOfAttorneys.length > 0;

  const userName = impersonatedUser?.customerNumber
    ? impersonatedUser?.name
    : selectedUser
    ? selectedUser.name
    : session?.user?.name;

  const handleExitImpersonation = async () => {
    await dropImpersonatedUser();
    router.push("/internal");
  };

  return (
    <Menu as="div" className="relative inline-block text-left text-defaultText">
      <HeaderMenu
        totalMessages={totalMessages}
        showNotifications={showNotifications}
        userName={userName}
        corporateUser={isCorporateUser}
      />
      <MenuItems className="bg-white rounded-md absolute right-0 mt-2 mr-[-8px] md:mr-0 border z-20 shadow-xl w-[auto] max-w-[333px] min-w-[250px]">
        {isAdvisorAndNoImpersonation ? (
          <UserMenuItem
            onClick={() => {
              window.location.href = "/api/auth/logout";
            }}
            iconName="arrowRightOnRectangleIcon"
            className="rounded-b-md border-t"
          >
            {TextResources.MENU_LOGOUT}
          </UserMenuItem>
        ) : (
          <>
            <div className="flex border-b py-4 px-4">
              <Typography variant="H6">{userName}</Typography>
            </div>
            {amlVerified && (
              <UserMenuItem onClick={() => router.push("/mailbox")} iconName="inboxIcon" className="rounded-t-lg">
                <div className="flex mr-[40px]">
                  <Typography variant="Body">{TextResources.MENU_MAILBOX}</Typography>
                </div>
                <NotificationItem count={totalMessages?.totalUnreadMessages} showNotifications={showNotifications} />
              </UserMenuItem>
            )}
            {showSwitchUser && (
              <SwitchUserItem
                isOpen={(isOpen || powerOfAttorneys?.some(p => p.customerNumber === selectedUser?.userId)) ?? false}
                setIsOpen={setIsOpen}
                powerOfAttorneys={powerOfAttorneys}
                selectedUser={selectedUser}
                session={session}
              />
            )}
            {amlVerified && (
              <UserMenuItem onClick={() => router.push("/profile")} iconName="userHeroIcon">
                {TextResources.MENU_MY_PROFILE}
              </UserMenuItem>
            )}
            {amlVerified && (
              <UserMenuItem onClick={() => router.push("/transfer?index=0")} iconName="circleStackIcon">
                {TextResources.MENU_TRANSFER_DEPOSIT}
              </UserMenuItem>
            )}
            {amlVerified && (
              <UserMenuItem onClick={() => router.push("/transfer?index=1")} iconName="bankNotesIcon">
                {TextResources.MENU_TRANSFER_WITHDRAWAL}
              </UserMenuItem>
            )}
            {amlVerified && (
              <UserMenuItem onClick={() => router.push("/transfer?index=2")} iconName="plusCircleIcon">
                {TextResources.MENU_NEW_ACCOUNT}
              </UserMenuItem>
            )}
            {amlVerified && (
              <UserMenuItem onClick={() => router.push("/poa")} iconName="usersIcon">
                {TextResources.MENU_POA}
              </UserMenuItem>
            )}
            {isAdvisor && (
              <UserMenuItem onClick={() => handleExitImpersonation()} iconName="xMarkIcon">
                {TextResources.MENU_EXIT_IMPERSONATION}
              </UserMenuItem>
            )}
            <UserMenuItem
              onClick={() => {
                window.location.href = `/api/auth/logout?centerId=${centerId}`;
              }}
              iconName="arrowRightOnRectangleIcon"
              className="rounded-b-md border-t"
            >
              {TextResources.MENU_LOGOUT}
            </UserMenuItem>
          </>
        )}
      </MenuItems>
    </Menu>
  );
};
