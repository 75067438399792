import useSWR, { SWRResponse } from "swr";
import { IUseAmlStatus } from "./types";
import { IAmlStatus } from "@portal/types/api/IAmlStatus";
import useAdvisor from "../useAdvisor";
import commonSwrConfig from "../commonSwrConfig";

const useAmlStatus: IUseAmlStatus = () => {
  const { isAdvisorAndNoImpersonation } = useAdvisor();

  const fetcher = async (url: URL): Promise<IAmlStatus> => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };

  const { data, isLoading, error, mutate }: SWRResponse<IAmlStatus, unknown> = useSWR(
    !isAdvisorAndNoImpersonation ? `/api/amlStatus` : null,
    fetcher,
    {
      ...commonSwrConfig(),
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  );

  return {
    data,
    error,
    isLoading,
    mutate,
  };
};

export default useAmlStatus;
