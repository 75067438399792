import { formatCurrencyWithGivenPrecision } from "@portal/utils/numberFormatters";

export const FormatAmount = (amount: number) => {
  return amount.toLocaleString().replace(/,/g, "  ");
};

export const getAmountNumber = (numberString: string): number => {
  let tmp = numberString.replaceAll(/\s/g, "").trim();
  tmp = tmp.replaceAll(",", ".").trim();
  return +tmp;
};

export const getFraction = (input: string) => {
  const delimiterPos = input.indexOf(",") > 0 ? input.indexOf(",") : input.indexOf(".");
  const fraction = delimiterPos > 0 ? input.substring(delimiterPos + 1) : "";
  return fraction;
};

export const formatNumberWithMaxDigits = (input: string, maximumFractionDigits: number) => {
  if (input.trim() === "," || input.trim() === ".") {
    return "0,";
  }

  const inputFraction = getFraction(input);
  const minimumFraction = inputFraction.length > maximumFractionDigits ? maximumFractionDigits : inputFraction.length;

  const number = getAmountNumber(input);
  let inputCurr = formatCurrencyWithGivenPrecision(number, {
    minimumFractionDigits: minimumFraction,
    maximumFractionDigits: maximumFractionDigits + 1,
  });

  const formattedFraction = getFraction(inputCurr);

  const fractionLengthOverLimit = formattedFraction.length - maximumFractionDigits;
  inputCurr = inputCurr.substring(0, inputCurr.length - fractionLengthOverLimit);
  return inputCurr.trim();
};
