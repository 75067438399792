import { ImpersonatedUser } from "iron-session";
import useSWR, { SWRResponse } from "swr";
import { IUseImpersonatedUser } from "./types";
import { useSession } from "next-auth/react";
import { clearOrders } from "@portal/redux/reducers/api/orders/reducer";
import { useDispatch } from "react-redux";
import { isAdvisor } from "@portal/helpers/portalRoleStrings";
import commonSwrConfig from "../commonSwrConfig";

const useImpersonatedUser: IUseImpersonatedUser = () => {
  const { data: session } = useSession();
  const dispatch = useDispatch();
  const fetcher = async (url: URL) => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };
  const { data, error, isLoading, mutate }: SWRResponse<ImpersonatedUser, unknown> = useSWR(
    isAdvisor(session?.role) ? `/api/internal/impersonate` : null,
    fetcher,
    commonSwrConfig(),
  );

  const storeImpersonatedUser = (user: ImpersonatedUser) => {
    return fetch(`/api/internal/impersonate`, {
      method: "POST",
      headers: [["accepts", "application/json"]],
      body: JSON.stringify(user),
    }).then(res => {
      dispatch(clearOrders());
      mutate();
      return res.json();
    });
  };

  const dropImpersonatedUser = () => {
    return fetch(`/api/internal/impersonate`, {
      method: "DELETE",
      headers: [["accepts", "application/json"]],
    }).then(() => {
      dispatch(clearOrders());
      mutate();
      return;
    });
  };

  return {
    data,
    error,
    isLoading,
    storeImpersonatedUser,
    dropImpersonatedUser,
  };
};

export default useImpersonatedUser;
