import { useEffect } from "react";
import { reInitialized } from "@portal/redux/reducers/app";
import { useAppDispatch } from "@portal/redux/store";
import useCenterId from "../useCenterId";

const useReInitializedTab = () => {
  const dispatch = useAppDispatch();
  const centerId = useCenterId();

  useEffect(() => {
    const handleReInitialized = (event: StorageEvent) => {
      if (event.key === "re-initialized" || event.key === "switch-user") {
        dispatch(reInitialized(centerId ?? ""));
      }
    };

    window.addEventListener("storage", handleReInitialized);

    return () => {
      window.removeEventListener("storage", handleReInitialized);
    };
  });
};

export default useReInitializedTab;
