import { IPosition } from "@portal/types/api/IPosition";
import { Dispatch } from "@reduxjs/toolkit";
import { executeCallbackWhenInfrontSDKIsReady } from "./infrontSDK";
import { IOrder } from "@portal/types/api/IOrder";
import { subscriber } from "./subscriber";
import { subscribeToFeed } from "./subscribeToFeed";

export const registerStockObserverForOrder = async (order: IOrder, dispatch: Dispatch) => {
  executeCallbackWhenInfrontSDKIsReady(() => {
    if (order.tickerCode) {
      subscriber(18177, order.tickerCode, dispatch);
      subscriber(18199, order.tickerCode, dispatch);
    }
  });
};

export const registerStockObserverForPositions = async (positions: IPosition[], dispatch: Dispatch) => {
  executeCallbackWhenInfrontSDKIsReady(() => {
    positions.forEach(p => {
      if (p.tickerCode) {
        subscriber(18177, p.tickerCode, dispatch);
        subscriber(18199, p.tickerCode, dispatch);
      }
    });
  });
};

export const registerStockObserverForOsloBors = async (dispatch: Dispatch) => {
  executeCallbackWhenInfrontSDKIsReady(() => {
    subscribeToFeed(18177, dispatch);
    subscribeToFeed(18199, dispatch);
  });
};
