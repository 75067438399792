import logos from "./logos";
import { FC } from "react";
import { IBankLogo } from "./types";
import { BankLogoContainer } from "./styles";
import { NORNE_CENTERID } from "@portal/assets/banks";

const BankLogo: FC<IBankLogo> = ({ centerId, size, color }) => {
  const Comp = GetLogoName(centerId);
  if (Comp === "") return <div>{Comp}</div>;

  const isNorne = centerId === NORNE_CENTERID;

  return (
    <BankLogoContainer color={color} size={size}>
      {isNorne ? (
        <Comp style={{ width: "140px", margin: "0px" }} />
      ) : (
        <Comp style={{ minWidth: GetMinWidthForLogo(centerId), minHeight: "40px", maxHeight: "60px", margin: "3px 0px" }} />
      )}
    </BankLogoContainer>
  );
};

const GetMinWidthForLogo = (centerId: string) => {
  if (centerId === "2220") return "80px";
  return "140px";
};

const GetLogoName = (centerId: string) => {
  if (centerId === "0000" || centerId === "") return logos["norneLightLogo"];
  if (centerId === "4530") return logos["norneLightLogo"];
  if (centerId === "3240") return logos["haugesundSparebankLogo"];
  if (centerId === "3361") return logos["skudenesOgAaakraSparebankLogo"];
  if (centerId === "3030") return logos["flekkefjordSparebankLogo"];
  if (centerId === "9820") return logos["obosBankLogo"];
  if (centerId === "3060") return logos["spareSkillingsbankenLogo"];
  if (centerId === "3090") return logos["søgneOgGreipstadSparebankLogo"];
  if (centerId === "3785") return logos["lusterSparebankLogo"];
  if (centerId === "2850") return logos["lillesandSparebankLogo"];
  if (centerId === "3601") return logos["sparebankenVestLogo"];
  if (centerId === "2220") return logos["sparebankenØstLogo"];
  if (centerId === "1254") return logos["culturaLogo"];
  if (centerId === "3543") return logos["etneSparebankLogo"];
  if (centerId === "2811") return logos["spareBankenSørLogo"];
  if (centerId === "1830") return logos["grueSparebankLogo"];
  if (centerId === "9090") return logos["eikaLogo"];
  if (centerId === "3890") return logos["sparebankenSognOgFjordaneLogo"];
  if (centerId === "1020") return logos["eidsbergSparebankLogo"];
  if (centerId === "2153") return logos["valdresSparebankLogo"];
  if (centerId === "3525") return logos["tysnesSparebankLogo"];
  if (centerId === "1310") return logos["strømmenSparebankLogo"];
  if (centerId === "4520") return logos["sparebankenNarvikLogo"];
  if (centerId === "2890") return logos["valleSparebankLogo"];
  if (centerId === "1140") return logos["trøgstadSparebankLogo"];
  if (centerId === "2050") return logos["totensSparebankLogo"];
  if (centerId === "4035") return logos["sunndalSparebankLogo"];
  if (centerId === "4336") return logos["statsbygdSparebankLogo"];
  if (centerId === "2601") return logos["skagerakSparebankLogo"];
  if (centerId === "1720") return logos["bienSparebankLogo"];
  if (centerId === "2351") return logos["skueSparebankLogo"];
  if (centerId === "4333") return logos["soknedalSparebankLogo"];
  if (centerId === "4312") return logos["hemneSparebankLogo"];
  if (centerId === "4345") return logos["åfjordSparebankLogo"];
  if (centerId === "1338") return logos["setskogSparebankLogo"];
  if (centerId === "4285") return logos["selbuSparebankLogo"];
  if (centerId === "1450") return logos["oslofjordSparebankLogo"]; // Fornebubanken
  if (centerId === "4111") return logos["rindalSparebankLogo"];
  if (centerId === "1286") return logos["romerikeSparebankLogo"];
  if (centerId === "4075") return logos["romsdalsbankenLogo"];
  if (centerId === "1870") return logos["odalSparebankLogo"];
  if (centerId === "4266") return logos["oppdalsbankenLogo"];
  if (centerId === "4060") return logos["ørskogSparebankLogo"];
  if (centerId === "3411") return logos["fanaSparebankLogo"];
  if (centerId === "4730") return logos["sekstiÅtteGraderNordLogo"];
  if (centerId === "4484") return logos["aasenSparebankLogo"];
  if (centerId === "2500") return logos["andebuSparebankLogo"];
  if (centerId === "1100") return logos["askimOgSpydebergSparebankLogo"];
  if (centerId === "1271") return logos["aurskogSparebankLogo"];
  if (centerId === "1105") return logos["bergSparebankLogo"];
  if (centerId === "2880") return logos["birkenesSparebankLogo"];
  if (centerId === "4295") return logos["bjugnSparebankLogo"];
  if (centerId === "2635") return logos["drangedalSparebankLogo"];
  if (centerId === "3543") return logos["etneSparebankLogo"];
  if (centerId === "3755") return logos["fjalerSparebankLogo"];
  if (centerId === "4448") return logos["grongSparebankLogo"];
  if (centerId === "4355") return logos["haltdalenSparebankLogo"];
  if (centerId === "4465") return logos["hegraSparebankLogo"];
  if (centerId === "3080") return logos["kvinesdalSparebankLogo"];
  if (centerId === "4230") return logos["melhusBankenLogo"];
  if (centerId === "2699") return logos["hjartdalBankenLogo"];
  if (centerId === "3353") return logos["hjelmelandSparebankLogo"];
  if (centerId === "1050") return logos["markerSparebankLogo"];
  if (centerId === "4358") return logos["nidarosSparebankLogo"];
  if (centerId === "1280") return logos["hølandSparebankLogo"];
  if (centerId === "4290") return logos["ørlandSparebankLogo"];
  if (centerId === "2510") return logos["larvikBankenLogo"];
  if (centerId === "1440") return logos["jernbanePersonaletsBankLogo"];
  if (centerId === "3290" || centerId === "3325") return logos["jærenSparebankLogo"];
  if (centerId === "2620") return logos["tinnSparebankLogo"];
  if (centerId === "3910") return logos["sparebankenMøreLogo"];
  if (centerId === "2895") return logos["agderSparebankLogo"];
  if (centerId === "3745") return logos["sognSparebankLogo"];
  if (centerId === "1321") return logos["romerikeSparebankLogo"];
  if (centerId === "2140") return logos["etnedalSparebankLogo"];
  if (centerId === "2901") return logos["evjeOgHornesSparebankLogo"];
  if (centerId === "2907") return logos["agderSparebankLogo"];
  if (centerId === "2333") return logos["skueSparebankLogo"];
  if (centerId === "2230") return logos["skueSparebankLogo"];
  if (centerId === "3730") return logos["sognSparebankLogo"];
  if (centerId === "2655") return logos["skagerakSparebankLogo"];
  if (centerId === "4589") return logos["sekstiÅtteGraderNordLogo"];
  if (centerId === "4605") return logos["sekstiÅtteGraderNordLogo"];
  if (centerId === "4001") return logos["norseSecuritiesLogo"];
  if (centerId === "4106") return logos["romsdalsbankenLogo"];
  if (centerId === "4260" || centerId === "4270") return logos["orklaSparebankLogo"];
  if (centerId === "4280") return logos["rørosBankenLogo"];
  if (centerId === "3305") return logos["sparebankenVestLogo"];
  if (centerId === "1135") return logos["askimOgSpydebergSparebankLogo"];
  if (centerId === "4040") return logos["surnadalSparebankLogo"];
  if (centerId === "1885") return logos["tolgaOsSparebankLogo"];
  if (centerId === "2146") return logos["valdresSparebankLogo"];
  if (centerId === "3800") return logos["sognSparebankLogo"];
  if (centerId === "3480") return logos["vossSparebankLogo"];
  if (centerId === "1010") return logos["vibrandLogo"];
  if (centerId === "2630") return logos["spareBankenDinLogo"];

  return "";
};
export default BankLogo;
