import { InfrontSDK } from "@infront/infront-sdk";
import featureFlags from "@portal/helpers/featureFlag";

export const InfrontSDKSingleton = (function () {
  let sdkInstance: InfrontSDK.SDK | null = null;
  let sdkReady = false;

  const createInstance = (infrontAccessToken: string) => {
    const infrontSDK = new InfrontSDK.SDK({
      signedToken: infrontAccessToken,
      onReady: () => {
        sdkReady = true;
      },
      onDisconnect: (event?: InfrontSDK.DisconnectEvent) => {
        console.warn("Disconnected:", event);
      },
    });
    return infrontSDK;
  };

  return {
    initializeSDK: (infrontAccessToken: string) => {
      if (!sdkInstance) {
        sdkInstance = createInstance(infrontAccessToken);
      }
      return sdkInstance;
    },
    getInstance: () => {
      if (!sdkInstance) {
        throw new Error("InfrontSDK is not initialized");
      }
      return sdkInstance;
    },
    isReady: () => sdkReady,
  };
})();

export const executeCallbackWhenInfrontSDKIsReady = (callback: () => void) => {
  if (!featureFlags.isInfrontEnabled) return;
  let count = 0;
  const interval = setInterval(() => {
    if (InfrontSDKSingleton.isReady()) {
      callback();
      clearInterval(interval);
    } else if (count > 5) {
      console.error("Timeout waiting for InfrontSDK to be ready");
      clearInterval(interval);
    }
    count++;
  }, 3000);
};
