import { useSession } from "next-auth/react";
import useSelectedPowerOfAttorney from "../useSelectedPowerOfAttorney";
import IsCorporateUser from "@portal/helpers/isCorporateUser";
import { IUseIsCorporateUser } from "./types";
import useAdvisor from "../useAdvisor";

const useIsCorporateUser: IUseIsCorporateUser = () => {
  const { data: session, status } = useSession();
  const { data: selectedUser, isLoading: poaLoading } = useSelectedPowerOfAttorney();
  const { isAdvisor, impersonatedCustomerNumber, isLoading: advisorIsLoading } = useAdvisor();

  const isCorporateUser = IsCorporateUser(
    isAdvisor ? impersonatedCustomerNumber : selectedUser?.userId ? selectedUser?.userId : session?.user.id,
  );

  return {
    isCorporateUser,
    isLoading: status === "loading" || poaLoading || advisorIsLoading,
  };
};

export default useIsCorporateUser;
