import Typography from "@portal/components/atoms/typography";
import Icon from "../icons";
import { TextResources } from "@portal/assets/text/TextResources";
import { AvatarCircle } from "./Avatar.styled";

interface Props {
  text: string | undefined;
  small?: boolean;
  userAvatar?: boolean;
  corporateUser?: boolean;
  showHat?: boolean;
}

/**
 * Component for a circle with an abbreviation of a name.
 * @param interface
 * @returns a circle with an abbreviation.
 */
export const AvatarComponent = ({ text, small, userAvatar, corporateUser, showHat = false }: Props) => {
  const abbreviation = userAvatar ? GetUserInitials(text) : GetAccountInitials(text);
  const variant = small ? "DescriptionSmall" : "Bold";

  return (
    <div className="flex relative">
      <div className={`${!showHat ? "hidden" : ""} absolute ${small ? "-top-4 left-1" : "-top-4 left-1.5"}  text-red`}>
        {/* <Icon name="santaHatIcon" size="small" /> */}
        <Icon name="easterBunnyFoldedEarsIcon" size="small" />
      </div>
      <AvatarCircle small={small} userAvatar={userAvatar}>
        {corporateUser ? (
          <Icon name="buildingOfficeIcon" size="small" />
        ) : (
          <Typography variant={variant}>{abbreviation}</Typography>
        )}
      </AvatarCircle>
    </div>
  );
};

const GetAccountInitials = (taxationType: string | undefined) => {
  return taxationType !== "" ? taxationType : TextResources.ACCOUNT_DEFAULT_AVATAR;
};

const GetUserInitials = (userName: string | undefined) => {
  const initials = userName
    ?.trim()
    .split(" ")
    .map(n => n[0]);
  if (!initials) return "";
  if (initials.length > 2) return `${initials[0]}${initials[initials.length - 1]}`;
  if (initials.length === 1) return `${initials[0]}`;

  return `${initials[0]}${initials[1]}`;
};
