import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@portal/redux/store";
import { IReceipt, ISwitchFund, ISwitchFundFormData, Steps } from "./types";
import { validateToFund } from "./validate";
import { ISwitchMultiFundOrderRequest } from "@portal/types/api/ISwitchMultiFundOrderRequest";

const initialState = {
  formData: {
    amount: "",
    quantity: 0,
    quantityAll: false,
    fromFundIsin: undefined,
    toFunds: [],
    portfolio: undefined,
    position: undefined,
  } as ISwitchFundFormData,
  amountError: "",
  toFundError: "",
  apiError: "",
  loading: false,
  receiptData: null,
  step: Steps.fromFund,
} as ISwitchFund;

export const switchFund = createAsyncThunk("switchFund", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const formData = state.other.ui.switchFund.formData;
  const toFundError = validateToFund(formData.toFunds);
  thunkAPI.dispatch(setToFundError(toFundError));

  const request = {
    portfolioId: formData.portfolio?.portfolioId,
    fundsToSell: [
      {
        isin: formData.fromFundIsin,
        quantity: formData.quantityAll ? formData.position?.quantity : Math.round(formData.quantity * 1000000) / 1000000,
      },
    ],
    fundsToBuy: formData.toFunds.map(f => ({ isin: f.isin, percent: f.percent })),
  } as ISwitchMultiFundOrderRequest;
  const url = "/api/funds/switch";
  const response = await fetch(url, {
    method: "POST",
    headers: [
      ["accepts", "application/json"],
      ["Content-Type", "application/json"],
    ],
    body: JSON.stringify(request),
  });
  if (!response.ok) {
    let errorText = "En feil oppstod ved bytte av fond. Vennligst prøv igjen seinere";
    try {
      const responseText = await response.text();
      if (responseText.indexOf("Ikke nok andeler for ordre") > -1) {
        errorText = "Ikke nok andeler for ordre, kontroller andre aktive ordre";
      }
    } catch (e) {
      // no-op
    }

    thunkAPI.dispatch(setApiError(errorText));
    throw new Error(response.statusText);
  }
});

export const switchFundReducer = createSlice({
  name: "switchFund",
  initialState,
  reducers: {
    setFormData: (state, action: PayloadAction<ISwitchFundFormData>) => {
      state.formData = action.payload;
    },
    setQuantity: (state, action: PayloadAction<number>) => {
      state.formData.quantity = action.payload;
    },
    setQuantityAll: (state, action: PayloadAction<boolean>) => {
      state.formData.quantityAll = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.formData.amount = action.payload;
    },
    setAmountError: (state, action: PayloadAction<string>) => {
      state.amountError = action.payload;
    },
    setApiError: (state, action: PayloadAction<string>) => {
      state.apiError = action.payload;
    },
    setReceiptData: (state, action: PayloadAction<IReceipt>) => {
      state.receiptData = action.payload;
    },
    setStep: (state, action: PayloadAction<Steps>) => {
      state.step = action.payload;
    },
    setToFundError: (state, action: PayloadAction<string>) => {
      state.toFundError = action.payload;
    },
    clearForm: state => {
      state.formData = initialState.formData;
      state.amountError = "";
      state.apiError = "";
      state.toFundError = "";
      state.loading = false;
    },
    clearStepAndReceipt: state => {
      state.step = Steps.fromFund;
      state.receiptData = null;
      state.apiError = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(switchFund.pending, state => {
        state.apiError = "";
        state.loading = true;
      })
      .addCase(switchFund.fulfilled, state => {
        state.loading = false;
        state.receiptData = {
          fromFundIsin: state.formData.fromFundIsin || "",
          toFunds: state.formData.toFunds,
          quantity: state.formData.quantityAll
            ? state.formData.position?.quantity ?? 0
            : Math.round(state.formData.quantity * 1000000) / 1000000,
        };
        state.formData = initialState.formData;
        state.step = Steps.receipt;
      })
      .addCase(switchFund.rejected, state => {
        state.loading = false;
        setApiError("En feil oppstod ved bytte av fond. Vennligst prøv igjen seinere");
      });
  },
});

export const {
  setFormData,
  setQuantity,
  setAmount,
  setAmountError,
  setApiError,
  setReceiptData,
  clearForm,
  setStep,
  setToFundError,
  clearStepAndReceipt,
  setQuantityAll,
} = switchFundReducer.actions;
export default switchFundReducer.reducer;
